export default class AppException {
  message: string;
  code: string;
  httpStatusCode: number;

  constructor(message = "", code = "", httpStatusCode = 0) {
    this.message = message;
    this.code = code;
    this.httpStatusCode = httpStatusCode;
  }
}