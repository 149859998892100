export default class CreatePasswordResetResponse {
  passwordResetCreated: boolean;
  emailSent: boolean;

  constructor() {}

  public static fromApi (data: any): CreatePasswordResetResponse {
    const model: CreatePasswordResetResponse = new CreatePasswordResetResponse();
    model.passwordResetCreated = data.passwordResetCreated;
    model.emailSent = data.emailSent;
    
    return model;
  }
}
