import * as msal from "@azure/msal-browser";
import Vue, { PluginObject, VueConstructor } from "vue";
import AuthStore from "@/store/AuthStore";

declare module "vue/types/vue" {
  interface Vue {
    $msal: MsalPlugin;
  }
}

export interface MsalPluginOptions {
  clientId: string;
  loginAuthority: string;
  cacheLocation: string;
  postLogoutRedirectUri: string;
  loginRedirectUri: string;
}

let msalInstance: msal.PublicClientApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

  private pluginOptions: MsalPluginOptions = {
    clientId: "",
    loginAuthority: "",
    cacheLocation: "",
    postLogoutRedirectUri: "",
    loginRedirectUri: ""
  };

  public isAuthenticated = false;

  public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified");
    }
    // console.log('registering a plugin, options:', options);
    this.pluginOptions = options;
    this.initialize(this.pluginOptions);
    msalPluginInstance = this;
    vue.prototype.$msal = Vue.observable(msalPluginInstance);
  }

  private initialize(options: MsalPluginOptions) {  
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        postLogoutRedirectUri: options.postLogoutRedirectUri,
        redirectUri: options.loginRedirectUri,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: options.cacheLocation
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                return;
              case msal.LogLevel.Info:
                // console.info(message);
                return;
              case msal.LogLevel.Verbose:
                // console.debug(message);
                return;
              case msal.LogLevel.Warning:
                // console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Error
        }
      }
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);

    this.isAuthenticated = this.getIsAuthenticated();
    AuthStore.setIsMsalAuthenticated({isAuthenticated: this.isAuthenticated, preventAutoRedirectOnAuthStateChange: false});

    AuthStore.setMsalRedirectPromiseInProgress(true);
    msalInstance.handleRedirectPromise()
      .then(redirectPromiseResponse => {
        if (redirectPromiseResponse !== null) {
          this.isAuthenticated = !!redirectPromiseResponse.account;

          // Get the custom state from the response which we stored prior to signin 
          const stateJsonObject = redirectPromiseResponse.state?.trim().startsWith("{") && redirectPromiseResponse.state?.trim().endsWith("}")
          ? JSON.parse(redirectPromiseResponse.state)
          : null;

          let newHref;
          let preventAutoRedirectOnAuthStateChange = false;
          if (stateJsonObject?.redirectUrl){
            const currentWindowLocation = window.location.origin;
            const redirectUrl = stateJsonObject.redirectUrl;
            if (redirectUrl && !this.isFileUrl(redirectUrl.toString())) {
              // We should redirect to new href, while preventing other redirects from triggering
              newHref = currentWindowLocation + redirectUrl;
              preventAutoRedirectOnAuthStateChange = true;
            }
          }
          AuthStore.setIsMsalAuthenticated({isAuthenticated: this.isAuthenticated, preventAutoRedirectOnAuthStateChange: false});
          
          if (newHref){
            // Run the actual redirect after setting the AuthStore state
            (window as any).location.href = newHref;
          }
          
        }
      })
      .catch(e => console.warn(e))
      .finally(() => AuthStore.setMsalRedirectPromiseInProgress(false))
  }

  public isFileUrl(url: string): boolean {
    const lastUrlPart = url.split('/').pop();
    return lastUrlPart ? lastUrlPart.indexOf('.') > -1 : false;
  }

  public async signIn(redirectUrl: string | (string | null)[]) {
    try {
      const loginRequest: msal.RedirectRequest = {
        scopes: ["user.read"],
        state: JSON.stringify({
          redirectUrl // custom state variable, stored as JSON
        }),
        redirectUri: this.pluginOptions.loginRedirectUri
      };
      // console.log('login request', loginRequest)
      return msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      // do nothing
    }
  }

  public async signOut() {
    const currentAccount = msalInstance.getActiveAccount();
    return msalInstance.logoutRedirect({
      onRedirectNavigate: (url) => {
        document.location.href = this.pluginOptions.postLogoutRedirectUri;
        return false;
      },
      // account: currentAccount,
      // postLogoutRedirectUri: this.pluginOptions.postLogoutRedirectUri
    });
  }

  public async acquireToken() {
    // console.log("Calling MSALplugin acquireToken");
    const request = {
      account: msalInstance.getAllAccounts()[0],
      scopes: [`${process.env.VUE_APP_MSAL_API_ACCESS_SCOPE}`]
    };
    try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      // console.log("catch acquireToken", error);
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(request);
      } else {
          console.warn(error);   
      }
    }
  }

  private getIsAuthenticated(): boolean {
    const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  }
}