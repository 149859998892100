<template>
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 231 44.15"
    class="syllabell-app-icon"
    :class="{ gradient, 'fill-height': fillHeight }"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-16.78"
        y1="18.08"
        x2="236.51"
        y2="18.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stop-color="#dba186" />
        <stop offset=".24" stop-color="#db7b9e" />
        <stop offset=".69" stop-color="#ad71ba" />
        <stop offset=".86" stop-color="#8b71ba" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-16.78"
        y1="27.16"
        x2="236.51"
        y2="27.16"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="-16.78"
        y1="17.19"
        x2="236.51"
        y2="17.19"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="-16.78"
        y1="17.19"
        x2="236.51"
        y2="17.19"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="-16.78"
        y1="22.27"
        x2="236.51"
        y2="22.27"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="-16.78"
        y1="17.41"
        x2="236.51"
        y2="17.41"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="-16.78"
        y1="22.27"
        x2="236.51"
        y2="22.27"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-8"
        x1="-16.78"
        y1="17.19"
        x2="236.51"
        y2="17.19"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-9"
        x1="-16.78"
        y1="17.19"
        x2="236.51"
        y2="17.19"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-10"
        x1="-16.78"
        y1="22.27"
        x2="236.51"
        y2="22.27"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-11"
        x1="-16.78"
        y1="17.47"
        x2="236.51"
        y2="17.47"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-12"
        x1="-16.78"
        y1="31.17"
        x2="236.51"
        y2="31.17"
        xlink:href="#linear-gradient"
      />
    </defs>
    <g id="Layer_2-2">
      <g>
        <path
          class="cls-1"
          d="M18.22,25.82c0-1.27-.45-2.24-1.34-2.92-.9-.68-2.51-1.39-4.83-2.15-2.33-.75-4.17-1.5-5.53-2.23C2.81,16.53,.96,13.84,.96,10.45c0-1.76,.5-3.33,1.49-4.71,.99-1.38,2.42-2.46,4.27-3.23,1.86-.78,3.94-1.16,6.25-1.16s4.4,.42,6.22,1.26c1.82,.84,3.23,2.03,4.24,3.57,.82,1.25,1.3,2.63,1.46,4.15,.06,.58-.39,1.08-.97,1.08h-4.79c-.49,0-.89-.37-.95-.85-.15-1.09-.6-1.97-1.35-2.63-.94-.83-2.26-1.24-3.96-1.24s-2.92,.35-3.83,1.04c-.91,.69-1.36,1.61-1.36,2.74,0,1.06,.53,1.95,1.6,2.66,1.07,.72,2.64,1.39,4.71,2.01,3.82,1.15,6.6,2.57,8.35,4.27s2.62,3.82,2.62,6.36c0,2.82-1.07,5.03-3.2,6.64-2.13,1.6-5.01,2.41-8.62,2.41-2.51,0-4.79-.46-6.85-1.38-2.06-.92-3.63-2.17-4.71-3.77C.68,28.35,.16,26.86,0,25.19c-.05-.57,.4-1.07,.97-1.07H5.78c.49,0,.9,.37,.96,.85,.33,3.03,2.46,4.54,6.4,4.54,1.6,0,2.84-.32,3.74-.97,.9-.65,1.34-1.56,1.34-2.72Z"
        />
        <path
          class="cls-6"
          d="M39.95,22.11l3.34-11.25c.12-.41,.5-.69,.93-.69h4.85c.67,0,1.13,.66,.91,1.29l-9.28,26.66s-.01,.04-.02,.06l-.53,1.25c-1.45,3.16-3.83,4.74-7.16,4.74-.68,0-1.37-.07-2.07-.22-.46-.1-.79-.49-.79-.95v-4.15s.98,.02,.98,.02c1.22,0,2.14-.19,2.74-.56,.6-.37,1.08-.99,1.42-1.86l.63-1.66c.08-.21,.09-.45,0-.66l-7.92-22.66c-.22-.63,.25-1.29,.91-1.29h4.88c.43,0,.8,.28,.93,.69l3.36,11.26c.28,.92,1.58,.92,1.86,0Z"
        />
        <path
          class="cls-4"
          d="M59.77,34.37h-4.55c-.53,0-.97-.43-.97-.97V.97c0-.53,.43-.97,.97-.97h4.55c.53,0,.97,.43,.97,.97V33.4c0,.53-.43,.97-.97,.97Z"
        />
        <path
          class="cls-5"
          d="M72.83,34.37h-4.55c-.53,0-.97-.43-.97-.97V.97c0-.53,.43-.97,.97-.97h4.55c.53,0,.97,.43,.97,.97V33.4c0,.53-.43,.97-.97,.97Z"
        />
        <path
          class="cls-9"
          d="M93.82,33.8c-.03-.08-.06-.15-.08-.23-.18-.55-.86-.78-1.31-.42-1.4,1.12-3.11,1.68-5.11,1.68-2.37,0-4.34-.69-5.9-2.06-1.56-1.37-2.34-3.1-2.34-5.19,0-2.57,.95-4.54,2.85-5.91,1.9-1.37,4.65-2.07,8.25-2.08h2.01c.53,0,.97-.43,.97-.97v-.42c0-1.12-.29-2.01-.86-2.69-.57-.67-1.48-1.01-2.72-1.01-1.09,0-1.94,.26-2.56,.78-.43,.36-.71,.81-.84,1.36-.11,.45-.48,.79-.95,.79h-4.55c-.59,0-1.06-.52-.96-1.1,.17-.99,.57-1.92,1.21-2.79,.87-1.19,2.09-2.13,3.67-2.81,1.58-.68,3.36-1.02,5.33-1.02,2.98,0,5.35,.75,7.11,2.25,1.75,1.5,2.63,3.61,2.63,6.32v10.49c.01,2.3,.34,4.04,.96,5.21v.38h-5.97c-.37,0-.69-.23-.81-.57Zm-5.1-3.92c.95,0,1.83-.21,2.64-.64,.71-.38,1.26-.87,1.65-1.47,.1-.15,.14-.34,.14-.52v-3.88h-2.42c-3.24,0-4.96,1.12-5.17,3.36l-.02,.38c0,.81,.28,1.47,.85,1.99,.57,.52,1.34,.78,2.33,.78Z"
        />
        <path
          class="cls-7"
          d="M127.35,22.49c0,3.88-.83,6.9-2.48,9.07s-3.97,3.26-6.94,3.26c-2.01,0-3.71-.59-5.1-1.77-.52-.44-1.32-.12-1.4,.56h0c-.05,.43-.42,.76-.85,.76h-4.08c-.53,0-.97-.43-.97-.97V.97c0-.53,.43-.97,.97-.97h4.53c.53,0,.97,.43,.97,.97V10.17c0,.76,.85,1.25,1.48,.82,1.24-.85,2.71-1.28,4.41-1.28,2.95,0,5.27,1.09,6.95,3.26,1.68,2.17,2.52,5.23,2.52,9.16v.36Zm-6.47-.47c0-2.45-.39-4.23-1.16-5.36-.78-1.13-1.93-1.69-3.47-1.69-2.06,0-3.48,.84-4.25,2.53v9.56c.79,1.7,2.22,2.55,4.3,2.55s3.46-1.03,4.12-3.09c.31-.98,.47-2.48,.47-4.5Z"
        />
        <path
          class="cls-8"
          d="M143.39,34.82c-3.55,0-6.44-1.09-8.67-3.27-2.23-2.18-3.35-5.08-3.35-8.71v-.63c0-2.43,.47-4.61,1.41-6.52,.94-1.92,2.27-3.39,3.99-4.43,1.72-1.04,3.69-1.56,5.9-1.56,3.31,0,5.92,1.04,7.82,3.13,1.9,2.09,2.85,5.05,2.85,8.88v1.67c0,.53-.43,.97-.97,.97h-14.45c.21,1.58,.84,2.85,1.89,3.8,1.05,.95,2.38,1.43,3.99,1.43,2.11,0,3.83-.65,5.16-1.95,.4-.39,1.03-.37,1.4,.04l1.92,2.15c.33,.37,.32,.92,0,1.29-.9,1.05-2.03,1.89-3.4,2.52-1.66,.77-3.49,1.15-5.51,1.15Zm-.74-19.89c-1.28,0-2.32,.43-3.12,1.3-.8,.87-1.31,2.11-1.53,3.73h9v-.52c-.03-1.44-.42-2.55-1.16-3.34-.75-.79-1.81-1.18-3.18-1.18Z"
        />
        <path
          class="cls-2"
          d="M163.8,34.37h-4.55c-.53,0-.97-.43-.97-.97V.97c0-.53,.43-.97,.97-.97h4.55c.53,0,.97,.43,.97,.97V33.4c0,.53-.43,.97-.97,.97Z"
        />
        <path
          class="cls-3"
          d="M176.86,34.37h-4.55c-.53,0-.97-.43-.97-.97V.97c0-.53,.43-.97,.97-.97h4.55c.53,0,.97,.43,.97,.97V33.4c0,.53-.43,.97-.97,.97Z"
        />
        <path
          class="cls-10"
          d="M212.06,33.73s0-.01,0-.02c-.22-.61-.96-.86-1.48-.47-1.38,1.06-3.05,1.58-5,1.58-2.37,0-4.34-.69-5.9-2.06-1.56-1.37-2.34-3.1-2.34-5.19,0-2.57,.95-4.54,2.85-5.91,1.9-1.37,4.65-2.07,8.25-2.08h2.98v-1.39c0-1.12-.29-2.01-.86-2.69-.57-.67-1.48-1.01-2.72-1.01-1.09,0-1.94,.26-2.56,.78-.43,.36-.71,.81-.84,1.36-.11,.45-.48,.79-.95,.79h-4.55c-.59,0-1.06-.52-.96-1.1,.17-.99,.57-1.92,1.21-2.79,.87-1.19,2.09-2.13,3.67-2.81,1.58-.68,3.36-1.02,5.33-1.02,2.98,0,5.35,.75,7.11,2.25,1.75,1.5,2.63,3.61,2.63,6.32v10.49c.01,2.3,.34,4.04,.96,5.21v.38h-5.89c-.41,0-.78-.26-.92-.65Zm-5.07-3.85c.95,0,1.83-.21,2.64-.64,.71-.38,1.26-.87,1.65-1.47,.1-.15,.14-.34,.14-.52v-3.88h-2.42c-3.24,0-4.96,1.12-5.17,3.36l-.02,.38c0,.81,.28,1.47,.85,1.99,.57,.52,1.34,.78,2.33,.78Z"
        />
        <path
          class="cls-12"
          d="M223.75,3.89c0-.97,.32-1.77,.97-2.39,.65-.63,1.53-.94,2.65-.94s1.98,.31,2.64,.94c.66,.63,.98,1.42,.98,2.39s-.33,1.79-1,2.42c-.66,.63-1.54,.94-2.63,.94s-1.97-.31-2.63-.94c-.66-.63-1-1.43-1-2.42Zm5.9,30.48h-4.55c-.53,0-.97-.43-.97-.97V11.13c0-.53,.43-.97,.97-.97h4.55c.53,0,.97,.43,.97,.97v22.28c0,.53-.43,.97-.97,.97Z"
        />
        <path
          class="cls-11"
          d="M184.41,31.19c0-1.03,.35-1.86,1.04-2.51,.69-.64,1.56-.96,2.61-.96s1.94,.32,2.63,.96c.69,.64,1.04,1.48,1.04,2.51s-.34,1.84-1.03,2.47c-.69,.63-1.57,.95-2.64,.95s-1.93-.32-2.62-.95c-.69-.63-1.03-1.46-1.03-2.47Z"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "SyllabellAppIcon",
  components: {},
})
export default class SyllabellAppIcon extends Vue {
  @Prop({ default: true })
  gradient!: boolean;

  @Prop({ default: false })
  fillHeight!: boolean;
}
</script>

<style lang="scss">
.syllabell-app-icon {
  width: 100%;
  height: auto;
  min-width: 50px;

  &.fill-height {
    height: 100%;
    width: auto;
  }

  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12 
  {
    fill: white;
  }

  &.gradient {
    .cls-1 {
      fill: url(#linear-gradient);
    }
    .cls-2 {
      fill: url(#linear-gradient-8);
    }
    .cls-3 {
      fill: url(#linear-gradient-9);
    }
    .cls-4 {
      fill: url(#linear-gradient-3);
    }
    .cls-5 {
      fill: url(#linear-gradient-4);
    }
    .cls-6 {
      fill: url(#linear-gradient-2);
    }
    .cls-7 {
      fill: url(#linear-gradient-6);
    }
    .cls-8 {
      fill: url(#linear-gradient-7);
    }
    .cls-9 {
      fill: url(#linear-gradient-5);
    }
    .cls-10 {
      fill: url(#linear-gradient-10);
    }
    .cls-10,
    .cls-11,
    .cls-12 {
      opacity: 0.7;
    }
    .cls-11 {
      fill: url(#linear-gradient-12);
    }
    .cls-12 {
      fill: url(#linear-gradient-11);
    }
  }
}
</style>