export default abstract class BaseService {

  static frontendRootPath = process.env.VUE_APP_FRONTEND_PATH && process.env.VUE_APP_FRONTEND_PATH != 'undefined'
    ? process.env.VUE_APP_FRONTEND_PATH 
    : process.env.NODE_ENV === "production" 
      ? ""
      : "/front";

  static externalApiURL: string = process.env.VUE_APP_API_HOST
    ? process.env.VUE_APP_API_HOST
    : process.env.NODE_ENV === "production" ? "" : "https://localhost:5001";


  static externalApiBaseURL: string = BaseService.externalApiURL + "";

  static baseURL: string = (
      process.env.VUE_APP_FRONTEND_PATH
        ? process.env.VUE_APP_FRONTEND_PATH 
        : ""
    ) + "/mockApi";
}
