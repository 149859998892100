export default class AccessToken {
  token: string;
  expiresAt: Date;

  constructor() {
  }

  public static fromApi (data: any): AccessToken {
    const model: AccessToken = new AccessToken();
    model.token = data.token;
    model.expiresAt = data.expiresAt;
    
    return model;
  }
}
