<template>
  <button 
    class="bitville-button"
    :class="{
      'bitville-button--green': color === 'green',
      'bitville-button--blue-white': color === 'blue-white',
      'bitville-button--green-white': color === 'green-white',
      'bitville-button--dark-grey': color === 'dark-grey',
      'bitville-button--light-grey': color === 'light-grey',
      'bitville-button--red-light-grey': color === 'red-light-grey',
      'bitville-button--grey-transparent': color === 'grey-transparent',
      'bitville-button--blue-transparent': color === 'blue-transparent',
      'small': size === 'small',
      'xsmall': size === 'xsmall',
      'form-button': formButton,
      'pulsing': pulsing,
      'full-width': fullWidth,
      'loading': loading,
      'disabled': disabled,
      'keep-hover': keepHover,
      'fully-transparent': fullyTransparent
    }"
    @click.prevent="onButtonClick"
  >
    <slot name="prepend-icon" class="bitville-button__icon" v-show="!loading">
    </slot>
    <span v-if="text" class="bitville-button__text">{{ text }}</span>
    <slot name="append-icon" class="bitville-button__icon" v-show="!loading">
    </slot>
  </button>
</template>

<script lang="ts">
import { Vue, Prop, Component } from "vue-property-decorator";

type buttonColor = 
'green' 
| 'blue' 
| 'blue-white' 
| 'green-white'
| 'dark-grey' 
| 'light-grey' 
| 'red-light-grey'
| 'grey-transparent'
| 'blue-transparent';

type sizeType = '' | 'xsmall' | 'small'

@Component({
  name: "BitvilleButton",
})
export default class BitvilleButton extends Vue {
  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  pulsing!: boolean;

  @Prop({ default: false })
  size!: sizeType;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  formButton!: boolean;

  @Prop({ default: "blue" })
  color!: buttonColor;

  @Prop({ default: "Button" })
  text!: string;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop({ default: false })
  keepHover!: boolean;

  @Prop({ default: false })
  fullyTransparent!: boolean;

  onButtonClick() {
    this.$emit("buttonClick");
  }
}
</script>

<style lang="scss">
.bitville-button {
  padding: $padding-button;
  outline: 0;
  border: none;
  font-size: 1.05rem;
  border-radius: $border-radius-small;
  box-shadow: none;
  background-color: $color-primary-blue;
  color: white;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  font-weight: 500;
  border: 2px solid transparent;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__text {
    display: inline-flex;
  }
  .v-icon {
    color: white !important;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &:active {
    outline: 0;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-dark-blue;
  }
}
.bitville-button--blue-white {
  color: $color-primary-blue;
  background-color: transparent;
  border: 2px solid $color-primary-blue;
  border-color: transparent;

  .v-icon {
    color: $color-primary-blue !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-blue;
    border-color: $color-light-blue;
  }
}
.bitville-button--green-white {
  color: $color-primary-green;
  background-color: white;
  border: 2px solid $color-primary-green;

  .v-icon {
    color: $color-primary-green !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-green;
    color: $color-dark-green-1;
    border-color: $color-light-green;
  }
}
.bitville-button--green {
  color: white;
  background-color: $color-primary-green;
  border: 2px solid $color-primary-green;

  .v-icon {
    color: white !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-dark-green-1;
    color: white;
    border-color: $color-dark-green-1;
  }
}
.bitville-button--dark-grey {
  color: white;
  background-color: $color-dark-grey-1;
  border: 2px solid $color-dark-grey-1;

  .v-icon {
    color: white !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-dark-grey-2;
    color: white;
    border-color: $color-dark-grey-2;
  }
}
.bitville-button--light-grey {
  color: $color-dark-temp;
  background-color: $color-light-grey-temp;
  border: 2px solid $color-light-grey-temp;

  .v-icon {
    color: $color-dark-2 !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-grey-3;
    color: $color-dark-temp;
    border-color: $color-light-grey-3;
  }
}
.bitville-button--red-light-grey {
  color: $color-primary-red;
  background-color: $color-light-grey-2;
  border: 2px solid $color-light-grey-2;

  .v-icon {
    color: $color-primary-red !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-grey-3;
    color: $color-primary-red;
    border-color: $color-light-grey-3;
  }
}
.bitville-button--grey-transparent {
  color: $color-dark-grey-1;
  background-color: transparent;
  border: 2px solid transparent;

  .v-icon {
    color: $color-dark-grey-1 !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-grey-3;
    color: $color-dark-grey-1;
    border-color: $color-light-grey-3;
  }
}
.bitville-button--blue-transparent {
  color: $color-primary-blue;
  background-color: transparent;
  border: 2px solid transparent;

  .v-icon {
    color: $color-primary-blue !important;
  }
  &:hover,
  &.keep-hover {
    background-color: $color-light-grey-3;
    color: $color-primary-blue;
    border-color: $color-light-grey-3;
  }
  &:active {
    background-color: $color-light-grey-4;
    border-color: $color-light-grey-4;
  }
}
.bitville-button.pulsing {
  animation: pulse 2s ease infinite;
}
.bitville-button.form-button {
  padding: $padding-form-button;
  margin-left: 1rem;
  border-color: transparent;
  &.bitville-button--blue-white:hover {
    background-color: $color-light-blue;
    color: $color-primary-blue;
  }
}
.bitville-button.small {
  font-size: 0.9rem;
  padding: $padding-button-small;
}
.bitville-button.xsmall {
  font-size: 0.85rem;
  padding: $padding-button-xsmall;
}
.bitville-button.full-width {
  width: 100%;
}
.bitville-button.loading {
  pointer-events: none;
  color: transparent;

  .bitville-button__text {
    opacity: 0;
  }
  .v-icon {
    opacity: 0;
  }
  &:after {
    content: '';
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-image: url('../assets/images/tail-loading-indicator-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.small:after {
    height: 20px;
    width: 20px;
  }
  &.xsmall:after {
    height: 18px;
    width: 18px;
  }
  &.bitville-button--white:after {
    background-image: url('../assets/images/tail-loading-indicator-blue.svg');
  }
  &.bitville-button--light-grey:after {
    background-image: url('../assets/images/tail-loading-indicator-blue.svg');
  }
}
.bitville-button.disabled {
  pointer-events: none;
  color: white;
  background-color: #bbbbbb;
  border-color: #bbbbbb;
  animation: none;

  .v-icon {
    color: white !important;
  }
  .bitville-button__text {
    color: white;
  }
}
.bitville-button.fully-transparent {
  user-select: none;
  pointer-events: none;
  opacity: 0;
}
.bitville-button .v-icon {
  font-size: 1.4rem !important;
}

@keyframes pulse {
  0% {
    background-color: $color-primary-blue;
  }
  50% {
    background-color: $color-dark-blue; // darken
  }
  100% {
    background-color: $color-primary-blue; // darken
  }
}
</style>