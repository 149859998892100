import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store';
import SnackbarNotification from '@/models/SnackbarNotification';

export interface INotificationStoreState {
  snackbarNotifications: SnackbarNotification[];
} 

// Hack due to issues
// https://github.com/championswimmer/vuex-module-decorators/issues/131
// https://github.com/championswimmer/vuex-module-decorators/issues/189
const name = 'notification'
if (store && store.state[name]) {
  store.unregisterModule(name)
}

@Module({ dynamic: true, namespaced: true, store, name })
export class NotificationStoreModule extends VuexModule implements INotificationStoreState {
  snackbarNotifications: SnackbarNotification[] = [];
  maxNotificationsVisible = 1;

  @Mutation
  private ADD_SNACKBAR_NOTIFICATION(notification: SnackbarNotification) {
    this.snackbarNotifications.push(notification);
  }
  @Action
  public addSnackbarNotification(notification: SnackbarNotification) {
    if (this.snackbarNotifications.length >= this.maxNotificationsVisible) {
      return;
    }
    this.ADD_SNACKBAR_NOTIFICATION(notification);
  }

  @Mutation
  private REMOVE_SNACKBAR_NOTIFICATION(notificationId: string) {
    const notificationIndex = this.snackbarNotifications.findIndex(n => n.id === notificationId);

    if (notificationIndex > -1) {
      this.snackbarNotifications.splice(notificationIndex, 1);
    }
  }
  @Action
  public removeSnackbarNotification(notificationId: string) {
    this.REMOVE_SNACKBAR_NOTIFICATION(notificationId);
  }
}

const NotificationStore = getModule(NotificationStoreModule);
export default NotificationStore;
