import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1b7de6',
        secondary: '#545454',
        accent: '#82B1FF',
        error: '#cf222e',
        danger: '#cf222e',
        info: '#1b7de6',
        success: '#2ea44f',
        warning: '#FFC107',
        primaryTextColor: '#181818',
        secondaryTextColor: '#363636',
        darkGreen: '#056805',
        orange: "#fd8c73",
      },
    },
  },
});
