export enum SnackbarNotificationType {
  Default = 0,
  Error,
  Success,
  Warning
}

export default class SnackbarNotification {
  id = "";
  type = SnackbarNotificationType.Default;
  message = "";
  color = "";

  constructor(type: SnackbarNotificationType, message: string) {
    this.id = SnackbarNotification.generateUniqueId();
    this.message = message;
    if (type === SnackbarNotificationType.Error) {
      this.color = 'error';
    } else if (type === SnackbarNotificationType.Success) {
      this.color = 'success';
    } else if (type === SnackbarNotificationType.Warning) {
      this.color = 'warning';
    } else {
      this.color = 'primary';
    }
  }

  static generateUniqueId(): string {
    return 'item_' + Math.random().toString(36).substr(2, 9);
  }
}