<template>
  <div class='page-loading'>
    <div class="page-loading__indicator-wrapper">
      <div class="page-loading__indicator">
        <div class='circle'>
          <div class='inner'></div>
        </div>
        <div class='circle'>
          <div class='inner'></div>
        </div>
        <div class='circle'>
          <div class='inner'></div>
        </div>
        <div class='circle'>
          <div class='inner'></div>
        </div>
        <div class='circle'>
          <div class='inner'></div>
        </div>
      </div>

      <span class="page-loading__loading-text">{{ visibleLoadingText }}</span>
    </div>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})

export default class PageLoading extends Vue {
  defaultLoadingText = "Loading..."

  @Prop({ default: "" })
  loadingText!: string;

  get visibleLoadingText() {
    return this.loadingText ? this.loadingText : this.defaultLoadingText;
  }
}
</script>

<style lang="scss">
.page-loading {
  width: 100%;
  height: 100%;
  background-color: $color-off-white;
  position: relative;
  margin: 0;

  &__loading-text {
    position: absolute;
    top: calc(40% + 135px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 400;
    color: $color-dark-grey-1;
    font-family: $font-family;
  }

  &__indicator-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  &__indicator {
    width: 100px;
    height: 102px;
    border-radius: 100%;
    position: absolute;
    top: 40%;
    left: calc(50% - 50px);
    animation: spin 5s infinite linear;

    .circle {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .circle .inner {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      // color: rgb(155, 203, 255);
      border: 5px solid rgba(38, 143, 255, 0.119);
      // border: 5px solid rgba(154, 203, 255, 0.886);
      border-right: none;
      border-top: none;
      background-clip: padding;
      box-shadow: inset 0px 0px 10px rgba(255, 143, 242, 0.15);
    }
    .circle:nth-of-type(0) {
      transform: rotate(0deg);
    }
    .circle:nth-of-type(0) .inner {
      animation: spin 2s infinite linear;
    }

    .circle:nth-of-type(1) {
      transform: rotate(70deg);
    }
    .circle:nth-of-type(1) .inner {
      animation: spin 2s infinite linear;
    }

    .circle:nth-of-type(2) {
      transform: rotate(140deg);
    }
    .circle:nth-of-type(2) .inner {
      animation: spin 2s infinite linear;
    }
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
