import AuthStore from "@/store/AuthStore";
import Vue from 'vue';

export default class AuthService {
  public static isAccessTokenValid = () => {
    const accessToken = AuthStore.accessToken;
    if (!accessToken) return false;

    const currTimestamp = Date.now();

    const nowDate = new Date(currTimestamp);
    const expiresAtDate = new Date(accessToken.expiresAt);

    const differenceBetweenDatesInSec = (+expiresAtDate - +nowDate) / 1000;

    // this method returns false 30sec before the actual expiration just to have some buffer
    return differenceBetweenDatesInSec > 30;
  }

  public static isRenewTokensUrl = (url: string | undefined) => {
    return url ? url.toLowerCase().includes("/account/renewtokens") : false;
  }
  
  public static async signoutFromApp() {
    await AuthStore.resetFrontendAccess();

    if (AuthStore.isMsalAuthenticated) {
      await Vue.prototype.$msal.signOut();
    }

    // if (AuthStore.isGoogleAuthenticated) {
    //   await Vue.prototype.$firebaseAuth.signOutWithGoogle();
    // }

    if (AuthStore.isCustomAuthenticated) {
      await AuthStore.resetAuthTokens();
    }

    (window as any).location.href = window.location.origin;
  }
}