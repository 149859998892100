<template>
  <SnackbarQueue
    :items="snackbarNotifications"
    next-button-count-text="More"
    closeButtonIcon="mdi-close"
    bottom
    right
    @remove="removeItem"
  ></SnackbarQueue>
</template>

<script lang="ts">
import SnackbarNotification from "@/models/SnackbarNotification";
import NotificationStore from "@/store/NotificationStore";
import { Vue, Component, Prop } from "vue-property-decorator";
import SnackbarQueue from "@/components/SnackbarQueue.vue";

@Component({
  name: "Snackbar",
  components: {
    SnackbarQueue
  }
})
export default class Snackbar extends Vue {
  get snackbarNotifications(): SnackbarNotification[] {
    return NotificationStore.snackbarNotifications;
  }

  removeItem(id: string) {
    NotificationStore.removeSnackbarNotification(id);
  }
}
</script>

<style lang="scss">

</style>