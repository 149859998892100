<template>
  <div>
    <v-snackbar
      :absolute="absolute"
      :bottom="bottom"
      :color="item.color"
      :key="item.id"
      :left="left"
      :multi-line="multiLine"
      :right="right"
      :timeout="timeout"
      :top="top"
      :value="i === 0"
      :vertical="vertical"
      v-for="(item, i) in items"
      class="snackbar-item"
    >
      {{ item.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="nextButtonColor"
          @click="removeItem(item.id)"
          text
          v-bind="attrs"
          v-if="items.length > 1"
        >
          {{ nextButtonText }} ({{ items.length - 1 }}
          {{ nextButtonCountText }})
        </v-btn>
        <v-btn
          :color="closeButtonColor"
          @click="removeItem(item.id)"
          text
          icon
          v-bind="attrs"
          v-else
          small
        >
          <v-icon small>{{ closeButtonIcon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import SnackbarNotification from "@/models/SnackbarNotification";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "SnackbarQueue",
})
export default class SnackbarQueue extends Vue {
  processing = false;
  timeoutId: number | undefined = undefined;

  @Watch("items")
  onItemsChange() {
    this.processItems();
  }

  @Prop({ default: false })
  absolute!: boolean;

  @Prop({ default: false })
  autoHeight!: boolean;

  @Prop({ default: false })
  bottom!: boolean;

  @Prop({ default: "white" })
  closeButtonColor!: string;

  @Prop({ default: "mdi-close" })
  closeButtonIcon!: string;

  @Prop({ required: true })
  items!: SnackbarNotification[];

  @Prop({ default: false })
  left!: boolean;

  @Prop({ default: false })
  multiLine!: boolean;

  @Prop({ default: "white" })
  nextButtonColor!: string;

  @Prop({ default: "More" })
  nextButtonCountText!: string;

  @Prop({ default: "Next" })
  nextButtonText!: string;

  @Prop({ default: false })
  right!: boolean;

  @Prop({ default: 25000 })
  timeout!: number;

  @Prop({ default: false })
  top!: boolean;

  @Prop({ default: false })
  vertical!: boolean;

  processItems() {
    this.processing = true;

    if (this.items && Array.isArray(this.items) && this.items.length > 0) {
      const item = this.items[0];
      return (this.timeoutId = window.setTimeout(() => {
        this.removeItem(item.id);
      }, this.timeout));
    }

    this.processing = false;
  }

  removeItem(id: string) {
    if (!this.timeoutId) {
      return;
    }
    clearTimeout(this.timeoutId);

    this.$emit("remove", id);

    if (this.items.length > 0) {
      return this.processItems();
    }
  }
}
</script>

<style lang="scss">
.snackbar-item {
  font-family: $font-family;
}
</style>
