<template>
  <div class="app-bar">
    <div class="app-bar__container">
      <div class="app-bar__logo-wrapper">
        <SyllabellAppIcon/>
      </div>

      <div class="app-bar__actions-container">
        <BitvilleButton 
          color="blue"
          :text="'Log in'"
          :size="'small'"
          @buttonClick="onLoginButtonClick"
        ></BitvilleButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import SyllabellAppIcon from "@/components/SyllabellAppIcon.vue";
import BitvilleButton from "@/components/BitvilleButton.vue";
import Routes from "@/Routes";
import AuthStore from "@/store/AuthStore";
import AppEvents from "@/AppEvents";

@Component({
  name: "AppBar",
  components: {
    SyllabellAppIcon,
    BitvilleButton
  }
})
export default class AppBar extends Vue {
  
  get isAuthenticated(): boolean {
    return AuthStore.isAuthenticated;
  }
  
  onLoginButtonClick() {
    if (this.isAuthenticated){
      this.$root.$emit(AppEvents.REDIRECT_TO_FRONTEND);
    } else {
      this.$router.push({ 
        name: Routes.signin.name
      });
    }
  }
}
</script>

<style lang="scss">
.app-bar {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $color-light-grey-2;
  width: 100%;
  padding: 0.6rem 1.5rem;
  background-color: #fff;
  position: fixed;
  z-index: 500;

  &__container {
    max-width: 3840px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
  }
  &__logo-wrapper {
    width: 90px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    box-sizing: border-box;
    margin-right: 3rem;
  }
}
</style>