import Vue from "vue";
import Vuex from "vuex";
import _ from 'lodash';
import { IAuthStoreState } from "./AuthStore";
import { IInviteStoreState } from "./InviteStore";
import { config } from 'vuex-module-decorators'
import { INotificationStoreState } from "./NotificationStore";

// Set rawError to true by default on all @Action decorators
config.rawError = true

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthStoreState;
  invite: IInviteStoreState;
  notification: INotificationStoreState;
}

export const localStorageKey = "bitville-translation-app-vuex-ls";

const getLocalStorageData = (): object | null => {
  const localStorageDataJson = localStorage.getItem(localStorageKey)
  if (!localStorageDataJson){
    return null;
  }
  let localStorageData: object | null = null;
  try {
    localStorageData = JSON.parse(localStorageDataJson);
  } catch (e){
    console.error("Error when parsing localStorage data as json:", e);
    return null;
  }
  return localStorageData;
}

export const readFromLocalStorage = <T>(path: string, defaultValue: T, modulePath: string | undefined = undefined): T => {
  const localStorageData: object | null = getLocalStorageData();
  if (localStorageData === null){
    return defaultValue;
  }

  const pathWithModule = modulePath
    ? modulePath + "." + path
    : path;

  const pathData = _.get(localStorageData, pathWithModule);
  if (pathData === undefined) {
    console.warn("Value for", path, " was not found in localStorage data");
    return defaultValue;
  }
  return pathData;
}

export const saveToLocalStorage = <T>(path: string, value: T, modulePath: string | undefined = undefined) => {
  let localStorageData: object | null = getLocalStorageData();
  if (localStorageData === null){
    localStorageData = {};
  }
  const pathWithModule = modulePath
    ? modulePath + "." + path
    : path;

  _.set(localStorageData, pathWithModule, value);
  const updatedLocalStorageDataJson: string = JSON.stringify(localStorageData);
  localStorage.setItem(localStorageKey, updatedLocalStorageDataJson);
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({ });
