var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"bitville-button",class:{
    'bitville-button--green': _vm.color === 'green',
    'bitville-button--blue-white': _vm.color === 'blue-white',
    'bitville-button--green-white': _vm.color === 'green-white',
    'bitville-button--dark-grey': _vm.color === 'dark-grey',
    'bitville-button--light-grey': _vm.color === 'light-grey',
    'bitville-button--red-light-grey': _vm.color === 'red-light-grey',
    'bitville-button--grey-transparent': _vm.color === 'grey-transparent',
    'bitville-button--blue-transparent': _vm.color === 'blue-transparent',
    'small': _vm.size === 'small',
    'xsmall': _vm.size === 'xsmall',
    'form-button': _vm.formButton,
    'pulsing': _vm.pulsing,
    'full-width': _vm.fullWidth,
    'loading': _vm.loading,
    'disabled': _vm.disabled,
    'keep-hover': _vm.keepHover,
    'fully-transparent': _vm.fullyTransparent
  },on:{"click":function($event){$event.preventDefault();return _vm.onButtonClick.apply(null, arguments)}}},[_vm._t("prepend-icon"),(_vm.text)?_c('span',{staticClass:"bitville-button__text"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._t("append-icon")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }