import { RouteConfig } from 'vue-router';

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

type ILegalViewTabSlug = 
  "terms-of-service" | 
  "privacy-policy"

export interface ILegalViewTab {
  name: string,
  slug: ILegalViewTabSlug,
  default?: boolean
}

class Routes {
  public static legalTabs: ILegalViewTab[] = [
    {
      name: "Terms of service",
      slug: "terms-of-service",
      default: true
    },
    {
      name: "Privacy policy",
      slug: "privacy-policy"
    }
  ];

  public static root: RouteConfig = {
    path: '/',
    name: 'Root',
    redirect: '/signin'
  };

  public static welcome: RouteConfig =  {
    path: '/welcome/:method?',
    name: 'Welcome',
    component: () => import("@/views/Signin.vue")
  };

  public static signin: RouteConfig =  {
    path: '/signin/:method?',
    name: 'Sign in',
    component: () => import("@/views/Signin.vue")
  };

  public static legal: RouteConfig =  {
    path: '/legal/:tabSlug?',
    name: 'Legal',
    meta: {
      showAppBar: true
    },
    component: () => import("@/views/Legal.vue")
  };

  public static notFound: RouteConfig = {
    path: '*',
    name: 'Not found',
    redirect: '/',
  };

  public static routes: RouteConfig[] = [
    Routes.root,
    Routes.welcome,
    Routes.signin,
    Routes.legal,
    Routes.notFound
  ];
}

export default Routes;
